// import external dependencies
import 'jquery';

// Import everything from autoload


// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import swiperHomepageGames from './components/swiper';

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
});

swiperHomepageGames;

var mySwiper = document.querySelector('.hp-games-swiper').swiper;

jQuery('.swiper-button-next').mouseenter(function() {
      mySwiper.params.autoplay.delay = 0;
      mySwiper.params.autoplay.reverseDirection = false;
      mySwiper.autoplay.start();
  });

  jQuery('.swiper-button-next').mouseleave(function() {
      mySwiper.autoplay.stop();
  });


  jQuery('.swiper-button-prev').mouseenter(function() {
    mySwiper.params.autoplay.delay = 0;
    mySwiper.params.autoplay.reverseDirection = true;
    mySwiper.autoplay.start();
  });

jQuery('.swiper-button-prev').mouseleave(function() {
    mySwiper.autoplay.stop();
});
    
// Load Events
jQuery(document).ready(() => routes.loadEvents());