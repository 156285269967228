import Swiper from 'swiper/bundle';

var swiperHomepageGames = new Swiper('.hp-games-swiper', {
  autoHeight: true, //enable auto height
  spaceBetween: 20,
  loop: true,
  speed: 1000,
  slidesPerView: 1,
  slidesPerGroup:1,
  grabCursor: true,
  mousewheelControl: true,
  keyboardControl: true,
  breakpoints: {
    640: {
      slidesPerView: 2,
      slidesPerGroup:1,
    },
    1024: {
      slidesPerView: 3,
      slidesPerGroup:1,
    },
    1200: {
      slidesPerView: 4,
      slidesPerGroup:1,
    },
  },
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});

swiperHomepageGames;
